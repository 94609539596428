export const SolarenUberdachungen =[
{
    titel:"Solarüberdachung",
    desc:"Diese Solarüberdachung symbolisiert den Wandel zu umweltfreundlicher Energiegewinnung. Ein Zeichen für Nachhaltigkeit und Fortschritt.",
    imgs :["IMG-20240208-WA0012.jpg"]
},
{
    titel:"Solar Terrassendach",
    desc:" Die schützende Überdachung bietet nicht nur Schutz für Fahrzeuge, sondern auch eine umweltfreundliche Energiequelle",
    imgs :["IMG-20240208-WA0022.jpg"]
},
{
    titel:"Solarüberdachung für den Garten",
    desc:"Diese Solarüberdachung im Garten liefert nicht nur Schatten, sondern auch grünen Strom. Eine clevere Investition für umweltbewusste Hausbesitzer.",
    imgs :["IMG-20240208-WA0008.jpg"]
},
]