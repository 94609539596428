import './App.css';
import axios from 'axios';
import React, { useEffect, useState ,useLocation } from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import FensterSection from './components/FensterSection';
import ItemDescreptio from './components/ItemDescreptio';
import Fotos from './components/Fotos';
import Solaren from './components/Solaren';
import ScrollToTop from './components/ScrollToTop';
import Treppen from './components/Treppen';
function App() {
  
  return (
    <Router >
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/Uberdachung" element={<FensterSection/>} />
          <Route path="/Uberdachung/:id" element={<ItemDescreptio/>} />
          <Route path="/Fotos" element={<Fotos/>} />
          <Route path="/Solaren" element={<Solaren/>} />
          <Route path="/Solaren/:id" element={<ItemDescreptio/>} />
          <Route path="/Treppen" element={<Treppen/>} />
          <Route path="/Treppen/:id" element={<ItemDescreptio/>} />
          
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
