import { NavLink } from 'react-router-dom';
import './Footer.css'
const Footer= () => {


    return (

        <footer
            class="text-center text-lg-start text-white footer"
           
        >


            <section class="">
                <div class="container text-center text-md-start mt-5">
                    <div class="row mt-3">
                        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 class="text-uppercase fw-bold">Allu System</h6>
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                            />
                            <NavLink to="/"><img alt="logo" className="logo" src={process.env.PUBLIC_URL + "/img/8f399e32c26846639cbf4b2038257cbf-removebg-preview.png"} />
                            </NavLink>  
                        </div>
                        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 class="text-uppercase fw-bold">Produkte</h6>
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                            />
                            <p>
                            <NavLink to="/Uberdachung"> 
                        Terrassenüberdachung
                        </NavLink> 
                            </p>
                            <p>
                            <NavLink to="/Treppen">
                        Treppengeländer Glas
                        </NavLink>
                            </p>
                            <p>
                            <NavLink to="/Solaren">
                            Solarüberdachung
                        </NavLink> 
                            </p>
                            <p>
                            <NavLink to="/Fotos"  >
                            Alle Projekte
                        </NavLink> 
                            </p>
                        </div>
                        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 class="text-uppercase fw-bold">Contact</h6>
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                            />
                            <p><i class='bx bxs-home'></i> Im Hock 17, 40721 Hilden</p>
                            <p><i class='bx bxs-envelope' ></i> info@alusystem-nrw.de</p>
                            <p><i class='bx bxs-phone'></i>0157 365 80 475</p>
                        </div>
                    </div>
                </div>
            </section>

            <div
                class="text-center p-3"
            >
               &#64; 2024 Copyright: alusystemnrw.de
            </div>
        </footer>

    );
};

export default Footer;