
export const Uberdachung =[
    {
        titel:"Elegante Terrassenüberdachung",
        desc:"Diese stilvolle Terrassenüberdachung bietet einen modernen und funktionalen Außenbereich. Das robuste Metallgestell mit dunkler Oberfläche stützt lichtdurchlässige Dachelemente, die natürliches Licht hereinlassen und gleichzeitig vor den Elementen schützen. Diese Überdachung ist eine hervorragende Ergänzung für jedes zeitgemäße Zuhause. Sie verbessert die ästhetische Anziehungskraft der Außenansicht und schafft einen einladenden Bereich zum Entspannen oder für gesellige Zusammenkünfte. Perfekt für alle, die ihren Außenbereich mit einer langlebigen und ansprechenden Lösung aufwerten möchten1.",
        imgs :["IMG-20240526-WA0022.jpg"]
    },
    {
        titel:"Terrassenüberdachung für Entspannung und Geselligkeit",
        desc:"Genießen Sie den Komfort unserer modernen Terrassenüberdachung, die sowohl funktional als auch ästhetisch ansprechend ist. Und Verbessern Sie die ästhetische Anziehungskraft Ihres Hauses mit unserer eleganten Überdachung",
        imgs :["IMG-20240526-WA0024.jpg"]
    },
    {
        titel:"Terrassenüberdachung: Eleganz für Ihren Außenbereich",
        desc:"Die dunkle Oberfläche und das natürliche Licht schaffen einen einladenden Raum für entspannte Stunden und gesellige Treffen.        ",
        imgs :["IMG-20240526-WA0030.jpg"]
    },
    {
        titel:"Terrassenüberdachung: Ästhetik und Komfort im Freien",
        desc:"Perfekt für entspannte Stunden und gesellige Zusammenkünfte – unsere Terrassenüberdachung bietet beides",
        imgs :["IMG-20240208-WA0015.jpg"]
    },
    {
        titel:"Modernes Design: Terrassenüberdachung für Ihr Zuhause",
        desc:"Schaffen Sie einen einladenden Bereich im Freien mit unserer stilvollen Überdachung,Und Verbessern Sie die ästhetische Anziehungskraft Ihres Hauses mit unserer eleganten Überdachung.",
        imgs :["IMG-20240526-WA0017.jpg"]
    },{
        titel:"Terrassenüberdachung: Robust und ästhetisch",
        desc:"Die perfekte Lösung für Ihren Außenbereich – funktional und stilvoll zugleich.",
        imgs :["IMG-20240526-WA0015.jpg"]
    },
    {
        titel:"Stilvolle Terrassenüberdachung",
        desc:"Die perfekte Lösung für Ihren Außenbereich – funktional und stilvoll zugleich.",
        imgs :["IMG-20240526-WA0027.jpg"]
    },{
        titel:"Funktional und ansprechend: Unsere Terrassenüberdachung",
        desc:"Schaffen Sie mit unserer stilvollen Terrassenüberdachung einen modernen Außenbereich, der sowohl funktional als auch ästhetisch ansprechend ist.",
        imgs :["main.jpg"]
    },
]