import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import './HomePage.css'
import CardSlider from './CardSlider';
const HomePage = () => {
    let [comentTitel, setcomentTitel] = useState("")
    let [comentDatum, setcomentDatum] = useState("")
    let [comentText, setcomentText] = useState("")
    let [Stern, setStern] = useState([])

    const SchowRate = e => {
        let titel = e.currentTarget.getElementsByTagName("h6")[0].innerText ;
        let datum = e.currentTarget.getElementsByClassName("datum-bewertung")[0].innerText;
        let text = e.currentTarget.getElementsByClassName("bewerungd-text")[0].innerText;
        let stern = Number(e.currentTarget.getAttribute("data-stern"))
        setcomentDatum(datum)
       setcomentText(text);
        setcomentTitel(titel)
        console.log(stern, Stern)
        let stars = Array.from({ length: 5 }).map((_, i) => (
            <i key={i} className={i >= stern ? 'bx bx-star ' : 'bx bxs-star'}></i>
        ));

        setStern(stars)
        e.currentTarget.parentElement.getElementsByClassName("popup")[0].classList.add("schow")
    }
    const CloseComent = (e) => {
        e.currentTarget.parentElement.parentElement.parentElement.classList.remove("schow")
        setcomentDatum("")
        setcomentText("");
        setcomentTitel("")
    }

    return (

        <div className="main-page">
            <section className="div_main_img">
                <img src={process.env.PUBLIC_URL + '/img/IMG-20240526-WA0014.jpg'} alt="main-logo" />
                <div className="main-img-desc">
                    <span className="titel">Beste Qualitat</span>
                    <div>
                        <span><i className='bx bx-check-square'></i>Terrassenüberdachung</span>
                        <span><i className='bx bx-check-square'></i>Treppengeländer Glas</span>
                        <span><i className='bx bx-check-square'></i>Solarüberdachung</span>
                        <span><i className='bx bx-check-square'></i>und mehr...</span>
                    </div>
                </div>
            </section>
            <section className="div_main_desc">
                <div className="text-main">
                    <h4>Terrassenüberdachung, Treppengeländer Glas und Solarüberdachung von Alu System einfach online konfigurieren</h4>
                    <span>

                    Vorstellbar ist es, zu jeder Zeit unter einer Terrassenüberdachung zu entspannen, ohne sich Gedanken über Sonne, Regen oder Wind machen zu müssen.                    </span>
                    <span>
                    Mittels einer Terrassenüberdachung aus unserer Produktion können Sie die Schönheiten der Natur ungeachtet des Wetters genießen. Sie können Ihre Gedanken schweifen lassen und die Wärme der Sonne auf Ihrem Gesicht spüren, während Sie gleichzeitig vor Regen und Wind geschützt sind.                    </span>

                    <span>
                    Unsere ausgereiften Produkte erleichtern die Gestaltung des Außenbereichs erheblich. Das Aluminiumdach bietet Lösungen für sämtliche Herausforderungen, darunter:                    </span>
                    <span>
                    Wahlen Sie aus unserem breiten Angebot funktionale und massgefertigte Teile, die Terrassen, Balkonen, Garten und Hinterhofen zusatzlichen Mehrwert verleihen. Diese Produkte sind der Zeit einen Schritt voraus                    </span>
                </div>

                <div className="list-product" >
                    <h5>Unsere ausgereiften Produkte :</h5>
                    <div className="list">
                        <span><i class='bx bx-check-double'></i>Aluminium-Terrassenüberdachungen</span>
                        <span><i class='bx bx-check-double'></i>Treppengeländer Glas </span>
                        <span><i class='bx bx-check-double'></i>Rahmenlose Glas-Schiebe-Systeme</span>
                        <span><i class='bx bx-check-double'></i>Festelemente und Seitenelemente</span>
                        <span><i class='bx bx-check-double'></i>Carports und Balkonuberdachungen</span>
                        <span><i class='bx bx-check-double'></i>Solarüberdachung</span>

                    </div>
                </div>
            </section>
            <section className="CardSlider">
                <h5>Unser Bestseller im Bereich der Überdachungen:</h5>
                <CardSlider />
            </section>
            <section className="project-imgs">
                <svg className="sid1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="white" fillOpacity="1" d="M0,0L1440,128L1440,0L0,0Z"></path>
                    </svg>

                <svg className="sid2"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="white" fillOpacity="1" d="M0,128L1440,320L1440,320L0,320Z"></path>
                </svg>
                <NavLink to="/Fotos" className="to-imges">MEHR SEHEN</NavLink>

                <div className="imges-div">
                    <div >
                        <img src={process.env.PUBLIC_URL + '/img/main.jpg'} alt="imges-projektes" />
                    </div>
                    <div >
                        <img src={process.env.PUBLIC_URL + '/img/IMG-20240526-WA0023.jpg'} alt="imges-projektes" />
                    </div>
                    <div >
                        <img src={process.env.PUBLIC_URL + '/img/img-project1.jpeg'} alt="imges-projektes" />
                    </div>
                    <div >
                        <img src={process.env.PUBLIC_URL + '/img/IMG-20240526-WA0002.jpg'} alt="imges-projektes" />
                    </div>
                    <div >
                        <img src={process.env.PUBLIC_URL + '/img/IMG-20240526-WA0030.jpg'} alt="imges-projektes" />
                    </div>
                </div>
            </section>
            <section className="servis-add">
                <h3>UNSER SERVICE</h3>
                <span className="span1">Genießen Sie im Frühjahr die frische Luft oder die Sonne im Sommer. Schützen Sie im Winter Ihre Möbel vor der Witterung. Eine Investition in ein Alu-System bringt Luxus in Ihre Immobilie und trägt zu ihrer langfristigen Wertsteigerung bei. Wir zeigen Ihnen gerne, was Sie verwirklichen können, und begleiten Sie von der ersten Beratung über die Planung bis hin zur Montage.</span>
                <span className="span2">Profitieren Sie von individuellen  Anpassungsmöglichkeiten<i class='bx bx-cog'></i> und einer einfachen Montage<i class='bx bx-wrench'></i></span>
            </section>
            <section className="bewertung-kunden">
                <div className="popup">
                    <div className="Bewertung-div ">
                        <div className="start-mark">
                            <div className="bewertungs-sterne">
                                {Stern}
                                
                            </div>
                            <i onClick={CloseComent}  class='bx bx-x'></i>
                        </div>
                        <span className="datum-bewertung">{comentDatum}</span>
                        <h6>{comentTitel}</h6>
                        <span className="">{comentText}</span>
                        </div>
                </div>
                <NavLink onClick={e => SchowRate(e)} data-stern="4">
                    <div className="Bewertung-div">
                        <div className="start-mark" >
                            <div className="bewertungs-sterne">
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bx-star color-warning'></i>
                            </div>
                            <i className='bx bxs-badge-check'></i>
                        </div>
                        <h6> Ich habe eine Terrassenüberdachung für unseren Garten gekauft und bin mehr als zufrieden. Die Qualität ist ausgezeichnet und die Installation war einfacher als erwartet. Der Kundenservice war sehr hilfsbereit und freundlich. Absolut empfehlenswert!</h6>
                        <span className="bewerungd-text">
                        Ich habe eine Terrassenüberdachung für unseren Garten gekauft und bin mehr als zufrieden. Die Qualität ist ausgezeichnet und die Installation war einfacher als erwartet. Der Kundenservice war sehr hilfsbereit und freundlich. Absolut empfehlenswert!</span>
                        <span className="mehr-lesen">Mehr lesen</span>
                    </div>
                </NavLink>
                <NavLink onClick={e => SchowRate(e)} data-stern="5">
                    <div className="Bewertung-div">
                        <div className="start-mark">
                            <div className="bewertungs-sterne">
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bx-star color-warning'></i>
                            </div>
                            <i className='bx bxs-badge-check'></i>
                        </div>
                        <span className="datum-bewertung">10.6.2023</span>
                        <h6> Ich habe sowohl eine Terrassenüberdachung als auch ein Treppengeländer aus Glas bestellt. Beide Produkte sind von hoher Qualität und sehen fantastisch aus. Besonders beeindruckt hat mich der exzellente Kundenservice. Fragen wurden schnell und kompetent beantwortet. Ich werde den Shop definitiv weiterempfehlen! </h6>
                        <span className="bewerungd-text">
                        Ich habe sowohl eine Terrassenüberdachung als auch ein Treppengeländer aus Glas bestellt. Beide Produkte sind von hoher Qualität und sehen fantastisch aus. Besonders beeindruckt hat mich der exzellente Kundenservice. Fragen wurden schnell und kompetent beantwortet. Ich werde den Shop definitiv weiterempfehlen! </span>
                        <span className="mehr-lesen">Mehr lesen</span>
                    </div>
                </NavLink>
                <NavLink onClick={e => SchowRate(e)} data-stern="3">
                    <div className="Bewertung-div">
                        <div className="start-mark">
                            <div className="bewertungs-sterne">
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                            </div>
                            <i className='bx bxs-badge-check'></i>
                        </div>
                        <span className="datum-bewertung">10.3.2023</span>
                        <h6> Die Lieferung war pünktlich und das Produkt entspricht genau meinen Erwartungen. Ich bin sehr zufrieden und werde meinen Freunden empfehlen.</h6>
                        <span className="bewerungd-text">
                        Die Lieferung war pünktlich und das Produkt entspricht genau meinen Erwartungen. Ich bin sehr zufrieden und werde  meinen Freunden empfehlen.</span>
                        <span className="mehr-lesen">Mehr lesen</span>
                    </div>
                </NavLink>
                <NavLink onClick={e => SchowRate(e)} data-stern="4">
                    <div className="Bewertung-div">
                        <div className="start-mark">
                            <div className="bewertungs-sterne">
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bxs-star'></i>
                                <i className='bx bx-star color-warning'></i>
                            </div>
                            <i className='bx bxs-badge-check'></i>
                        </div>
                        <span className="datum-bewertung">10.3.2023</span>
                        <h6> Die Solarüberdachung ist ein echter Hingucker in unserem Garten! </h6>
                        <span className="bewerungd-text">
                        Die Solarüberdachung ist ein echter Hingucker in unserem Garten! </span>
                        <span className="mehr-lesen">Mehr lesen</span>
                    </div>
                </NavLink>
            </section>

            <section className="sevice-div">
                <div>
                    <span className="span-hide"><span className="bold">Service</span>
                        & unsere Produkte</span>
                    <span><span className="bold">Kundendienst</span>
                        Wir sind telefonisch
                        365 Tage im Jahr erreichbar</span>

                    <span className="span-hide"><span className="bold">Sicheres Bestelle</span>
                        Bestellen Sie sicher und
                        einfach in unserem Webshop</span>
                </div>
            </section>
        </div>

    );
};

export default HomePage;