export const TreppenData =[
    {
        titel:"Treppengeländer Glas",
        desc:"Ein Treppengeländer aus Glas verleiht Ihren Wohnräumen ein modernes, offenes Wohngefühl und macht Ihre Treppe zum absoluten Hingucker. Diese transparenten, filigranen Glasgeländer bieten nicht nur ästhetische Vorteile, sondern auch maximale Sicherheit beim Treppensteigen.",
        imgs :["IMG-20240526-WA0009.jpg","IMG-20240526-WA0005.jpg"]
    },
    {
        titel:"Glasgeländer für Treppen",
        desc:" Ein Treppengeländer aus Glas bringt ein modernes, luftiges Wohngefühl in Ihre Räume und macht Ihre Treppe zu einem echten Highlight. Diese transparenten und eleganten Glasgeländer bieten nicht nur eine ansprechende Optik, sondern auch maximale Sicherheit beim Treppensteigen.",
        imgs :["IMG-20240526-WA0004.jpg","IMG-20240526-WA0003.jpg","IMG-20240526-WA0012.jpg","IMG-20240526-WA0013.jpg","IMG-20240526-WA0006.jpg"]
    }
    ]