import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import "./ProductItem.css"

const ProductItem = ({i,link , titel, desc, imgs }) => {


    return (

        <div className="item">
            <img src={process.env.PUBLIC_URL+'/img/'+imgs[0]} alt="Fenster" />

            <div class="desct-item">
                <NavLink to="/ItemDescreptio"> <h2>{titel}
                </h2></NavLink>

                <span className="txt">
                   {desc}
                </span>
               

                <div className="btn-item">

                   
                    <NavLink to={"/"+link+"/"+i} className="product-info" data-item-titel="my first Item"> <span>Zum Produkt</span> <i class='bx bx-chevron-right'></i></NavLink>
                </div>
            </div>
        </div>

    );
};

export default ProductItem;