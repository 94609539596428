import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ProductItem from './ProductItem';
import "./ProductSection.css"
import { TreppenData } from '../data/TreppenDt';

const Treppen= () => {


    return (
        <div className="content">
            <div className="sortierung">
                
            </div>
        <div className="ProductSection">
            
        {
                TreppenData.map((e,i) => 
                (
                    <ProductItem key={i} link="Treppen" i={i} titel={e.titel} desc={e.desc} imgs={e.imgs}/>
                ))
            }
        </div>
            
       </div> 

    );
};

export default Treppen;