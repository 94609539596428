import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ProductItem from './ProductItem';
import "./ProductSection.css"
import { SolarenUberdachungen } from '../data/SolarenUB';

const Solaren = () => {


    return (
        <div className="content">
            <div className="sortierung">
                
            </div>
        <div className="ProductSection">
            
        {
                SolarenUberdachungen.map((e,i) => 
                (
                    <ProductItem key={i} link="Solaren" i={i} titel={e.titel} desc={e.desc} imgs={e.imgs}/>
                ))
            }
        </div>
            
       </div> 

    );
};

export default Solaren;