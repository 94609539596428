import React, { Component } from 'react';
import NavMenu  from './NavMenu';
import './Layout.css';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
      return (
          <div className='layoutdiv' >
       <NavMenu />
        <Outlet />
         <Footer/>
      </div>
    );
  }
}
