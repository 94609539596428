import React, { useState, useRef, useEffect } from "react";
import "./ItemDescreptio.css"
import { NavLink ,useLocation ,useParams} from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Uberdachung } from "../data/Uberdachungen";
import { SolarenUberdachungen } from '../data/SolarenUB';
import { TreppenData } from '../data/TreppenDt';


const ItemDescreptio = () => {
    const [images, setImages] = useState([]);
    const [titel, setTitel] = useState("");
    const [Desc, setDesc] = useState("");
    const [scren, setScren] = useState(window.innerWidth);
    const location = useLocation();
    const pathSegments = location.pathname.split('/'); // Split the path by '/'
    const category = pathSegments[1];
    const { id } = useParams();

    useEffect(() => {
        const fetchData = () => {
            if (category === "Uberdachung" && Uberdachung[id]) {
                const data = Uberdachung[id];
                setImages(data.imgs);
                setActiveImage(data.imgs[0]); // Set initial active image
                setDesc(data.desc)
                setTitel(data.titel)
            }
            if (category === "Solaren" && SolarenUberdachungen[id]) {
                const data = SolarenUberdachungen[id];
                setImages(data.imgs);
                setActiveImage(data.imgs[0]); // Set initial active image
                setDesc(data.desc)
                setTitel(data.titel)
            }
            if (category === "Treppen" && TreppenData[id]) {
                const data = TreppenData[id];
                setImages(data.imgs);
                setActiveImage(data.imgs[0]); // Set initial active image
                setDesc(data.desc)
                setTitel(data.titel)
            }
        };

        fetchData(); 
    }, [category, id]); 
    useEffect(() => {
       
            const handleResize = () => {
                setScren(window.innerWidth);
            };

            window.addEventListener('resize', handleResize);

            // Cleanup function to remove the event listener when the component unmounts
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        
    }, []); 
    const [activeImg, setActiveImage] = useState(images[0]);

    return (
        <div className="parent-desc">
            <div className="cart-desc">
                <h2>{titel}</h2>
                <div className="product-info-desc">


                    <div className='img-galarie'>
                        <div className='side-img'>
                           {
                            images.length >1 && (
                                images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={process.env.PUBLIC_URL+ "/img/"+ image}
                                        alt=""
                                        className='cursor-pointer'
                                        onClick={() => setActiveImage(image)}
                                        style={{
                                            width: scren <= 1201 ? `${100 / images.length}%` : '100%',
                                            height: `${100 / images.length}%`
                                        }}
    
                                    />
                                ))
                            )
                           }
                        </div>
                        <div className="main_img">
                            <img src={process.env.PUBLIC_URL+ "/img/"+activeImg} alt="" className='' />
                        </div>
                    </div>
                        


                </div>
            </div>
            <div className="des-prise">
                <div className="discription">
                    <span className="div-price"><span className="price">fairer Preis</span></span>
                    <div className="secend-info">
                        <span><i class='bx bxs-circle'></i> Versandkostenfreie Lieferung!</span>
                        <span><i class='bx bxs-circle'></i> Lieferzeit ca. 8-12 Wochen</span>
                    </div>
                </div>


            </div>
            
                <div className="beschreibung">
                    <h6>Beschreibung</h6>
                    <div>
                        <p> {Desc}
                    </p>
                    <p>
                        Kostenlose Online-Beratung: Wenn Sie unsicher sind oder Fragen haben, können Sie uns anrufen. Wir beantworten Ihre Fragen gerne telefonisch. Häufig ist es aber sinnvoll, uns von Ihren örtlichen Gegebenheiten Fotos zu schicken. Das können Sie gerne per E-Mail oder per WhatsApp tun.
                    </p>
                    </div>
                </div>

            
        </div>

    );
};

export default ItemDescreptio;