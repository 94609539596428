export const Allfotos =[
"IMG-20240526-WA0023.jpg",
"IMG-20240526-WA0022.jpg",
"IMG-20240526-WA0016.jpg",
"IMG-20240526-WA0021.jpg",
"IMG-20240526-WA0008.jpg",
"IMG-20240526-WA0026.jpg",
"IMG-20240526-WA0020.jpg",
"IMG-20240526-WA0010.jpg",
"IMG-20240526-WA0029.jpg",
"IMG-20240526-WA0014.jpg",
"IMG-20240526-WA0005.jpg",
"IMG-20240526-WA0019.jpg",
"IMG-20240526-WA0001.jpg",
"IMG-20240526-WA0030.jpg",
"IMG-20240526-WA0013.jpg",
"IMG-20240526-WA0009.jpg",
"IMG-20240526-WA0017.jpg",
"IMG-20240526-WA0027.jpg",
"IMG-20240526-WA0004.jpg",
"IMG-20240526-WA0002.jpg",
"IMG-20240526-WA0003.jpg",
"IMG-20240526-WA0006.jpg",
"IMG-20240526-WA0015.jpg",
"IMG-20240526-WA0025.jpg",
"IMG-20240526-WA0018.jpg",
"IMG-20240526-WA0024.jpg",
"IMG-20240526-WA0007.jpg",
"IMG-20240526-WA0031.jpg",
"IMG-20240526-WA0012.jpg",
"IMG-20240526-WA0006.jpg",
"IMG-20240208-WA0023.jpg",
"IMG-20240208-WA0024.jpg",
"IMG-20240208-WA0012.jpg",
"IMG-20240208-WA0020.jpg",
"IMG-20240208-WA0007.jpg",
"IMG-20240208-WA0015.jpg",
"IMG-20240208-WA0014.jpg",
"IMG-20240208-WA0021.jpg",
"IMG-20240208-WA0006.jpg",
"IMG-20240208-WA0018.jpg",
"IMG-20240208-WA0016.jpg",
"IMG-20240208-WA0005.jpg",
"IMG-20240208-WA0009.jpg",
"IMG-20240208-WA0017.jpg",
"IMG-20240208-WA0025.jpg",
"IMG-20240208-WA0013.jpg",
"IMG-20240208-WA0008.jpg",
"IMG-20240208-WA0022.jpg",
"IMG-20240208-WA0008.jpg"
]