import "./Fotos.css"
import { Allfotos } from "./AllFotos";

const Fotos = () => {


    return (
        <div className="main-div">
          {
            Allfotos.map((e,i) =>(
                <img key={i} src={process.env.PUBLIC_URL + '/img/' +e} alt="imges-projektes" />
                   
            ))
          }
       </div> 

    );
};

export default Fotos;