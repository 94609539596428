import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './CardSlider .css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Uberdachung } from "../data/Uberdachungen";

const CardSlider = () => {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#EEEEEE", borderRadius: "50%" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "#EEEEEE", borderRadius: "50%" }}
                onClick={onClick}
            />
        );
    }
    const myData= Uberdachung.slice(0,4)
    const [card, setCard] = useState(myData);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: true
    };
    return (

        <div className="mycards">
           
            <Slider {...settings}>
                {myData.map((e,i) => (
                    <NavLink key={i}>
                        <div className="mycard" key={i}>
                            <div className="card_img">
                                <img src={process.env.PUBLIC_URL+"/img/" + e.imgs[0]} alt="slid-img" />
                            </div>

                            <div className="desc">
                                <span className='titel'>{e.desc}</span>
                            </div>
                        </div>
                    </NavLink>

            
            ))

                }
                </Slider >
        </div>

    );
};

export default CardSlider;